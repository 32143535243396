/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import AspCartBtnBox from "../../src/component/parts/aspCartBtnBox";
import HeadlineH2 from "../../src/component/parts/headlineH2";
import HeadlineH1 from "../../src/component/parts/headlineH1";
import HeadlineH4 from "../../src/component/parts/headlineH4";
import MovieInfoTable from "../../src/component/parts/movieInfoTable";
import PerformerInfoTable from "../../src/component/parts/performerInfoTable";
import MovieLeadText from "../../src/component/parts/movieLeadText";
import ReviewText from "../../src/component/parts/reviewText";
import FiveStarRating from "../../src/component/parts/fiveStarRating";
import Goodpoints from "../../src/component/parts/goodpoints";
import Badpoints from "../../src/component/parts/badpoints";
import PrefaceText from "../../src/component/parts/prefacetext";
import {Carousel} from "react-bootstrap";
function _createMdxContent(props) {
  const _components = Object.assign({
    hr: "hr",
    h1: "h1",
    img: "img",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  if (!Carousel) _missingMdxReference("Carousel", false);
  if (!Carousel.Item) _missingMdxReference("Carousel.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.hr), "\n", React.createElement(_components.h1, null, "【豊満ぽっちゃり美熟女】AV女優出演アダルトビデオ情報まとめ【2022年最新】"), "\n", React.createElement(_components.hr), "\n", React.createElement(PrefaceText, {
    preface: "豊満ぽっちゃり美熟女AV女優出演アダルトビデオを紹介していきます。やれダイエットだ・やれモデル体型だの世間はルッキズムに染まっている今日ですが、男性陣は若くてモデル体型よりも豊満ぽっちゃり美熟女が好きなんです。包まれたいし甘やかされたいと感じているのです。サイト管理人のレビューや、出演されている女優さんの詳しい情報などをまとめて記事にして紹介しています。アダルト動画の魅力や見どころ、出演されている女優さんの魅力、マニアも唸らせるフェチズムを満たす映像演出などについて、詳しく紹介していきます。"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/1.jpg",
    alt: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか",
    actress: "大森しずか",
    release: "2019/05/25",
    time: "147",
    manufacturer: "Fitch",
    lavel: "NIKUYOKU"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "今話題の美熟女豊満女優・大森しずかがFitch初登場！そのたわわに実ったLカップ猛乳を振り乱しつつも心を込めて気持ち良いマッサージを一生懸命いたします！もちろんただのマッサージではございません！ここだけの話、呼んだお客様にしか分からない秘密のサービスがございます。豊満で極上の肉質を駆使したテクニックで思う存分肉に埋もれてください！しずか嬢によるリピート続出の快感サービスをじっくりご覧あれ！"
  }), "\n", React.createElement(ReviewText, {
    reviewText: ""
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/1/juny00007jp-1.jpg",
    alt: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/1/juny00007jp-2.jpg",
    alt: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/1/juny00007jp-3.jpg",
    alt: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/1/juny00007jp-4.jpg",
    alt: "猛乳Lカップを振り乱すドスケベ出張エステティシャン 大森しずか"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Djuny00007%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(PerformerInfoTable, {
    name: "大森しずか",
    year: "1981",
    month: "8",
    day: "30",
    height: "163",
    bust: "128",
    waist: "72",
    hip: "118",
    belonging: "ARROWS",
    sns: React.createElement(_components.ul, null, React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://twitter.com/shizuka_arrows",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Twitter"))),
    debut: "初撮り人妻ドキュメント大森しずか",
    debutFanza: "yes",
    debutFanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dh_086jrzd00843%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    debutSokumil: "yes",
    debutSokumilUrl: "https://www.sokmil.com/av/_item/item297833.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/2.jpg",
    alt: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり",
    actress: "本真ゆり",
    release: "2020/07/17",
    time: "2020/07/17",
    series: "THE ドキュメント 本能丸出しでする絶頂SEX",
    manufacturer: "美人魔女/エマニエル",
    lavel: "美人魔女",
    m: true
  }), "\n", React.createElement(MovieLeadText, {
    readText: "男を惑わすムチムチエロBODY、タワワに揺れる101cmIカップピッタリボディコン、黒パンスト得意プレイは勿論パイズリ！フェラ好きだというゆりは、「舐められるより舐める方が好き…」フェチボディを接写でチェック、バナナを美味しそうに頬張り疑似フェラ、カメラの前で洋服の生着替え！カメラ目線でエロ過ぎるゆりがあなたを誘う！"
  }), "\n", React.createElement(ReviewText, {
    reviewText: "29歳の方を熟女と呼称するのは些か語弊があるのではないかと逡巡しましたが、いい尻をされていたのでレビューしたいという己の欲望に逆らえませんでした。"
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/2/bijn00182jp-1.jpg",
    alt: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/2/bijn00182jp-2.jpg",
    alt: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/2/bijn00182jp-3.jpg",
    alt: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/2/bijn00182jp-4.jpg",
    alt: "THE ドキュメント 本能丸出しでする絶頂SEX バスト101cm Iカップ 巨乳肉感フェチBODY 本真ゆり"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item358750.htm?ref=search",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dbijn00182%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(PerformerInfoTable, {
    name: "本真ゆり",
    alias: "相内つかさ",
    year: "1993",
    month: "1",
    day: "28",
    height: "162",
    bust: "100",
    waist: "64",
    hip: "100",
    blood: "O",
    birthplace: "東京都",
    hobby: "料理",
    belonging: "ベネスプロモーション",
    sns: React.createElement(_components.ul, null, React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://twitter.com/honma_yuri",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Twitter")), React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://www.instagram.com/yuri_honma_official/?hl=ja",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Instagram"))),
    skill: "韓国語",
    debut: "「スポーツマッサージ師になりすまして美人アスリートの尻に勃起チ○ポを擦りつけたらヤられた」 VOL.1",
    debutFanza: "yes",
    debutFanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3D1dandy00259%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    debutSokumil: "yes",
    debutSokumilUrl: "https://www.sokmil.com/av/_item/item116347.htm?ref=search"
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(HeadlineH4, {
    h4: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/3.jpg",
    alt: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～",
    actress: "本田彩芽",
    release: "2022/06/04",
    time: "115",
    manufacturer: "乳と母/エマニエル",
    lavel: "乳と母"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "マザコンの私からすれば、こんな綺麗で優しくてオッパイデカくてヤラしくて痴女なお母さんなんてまさに完璧だなと思いました。憧れです（笑）。息子さんも昔からそうした感情はあったんじゃないでしょうか？（笑）。これじゃあ応援するつもりが逆効果ですねwでも羨ましい限りです。彩芽さん、最高です。"
  }), "\n", React.createElement(ReviewText, {
    reviewText: ""
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/3/chch00020jp-1.jpg",
    alt: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/3/chch00020jp-2.jpg",
    alt: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/3/chch00020jp-3.jpg",
    alt: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/3/chch00020jp-4.jpg",
    alt: "息子を溺愛する爆乳ママ ～受験前の息子を元気づけるため一晩で大盤振る舞い7射精～"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dchch00020%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(PerformerInfoTable, {
    name: "本田彩芽",
    alias: "美月みさと 早川友里子",
    year: "1975",
    month: "3",
    day: "19",
    height: "163",
    bust: "100",
    waist: "67",
    hip: "92",
    hobby: "スノーボード ドライブ ゲーム 旅行",
    belonging: "Zeal Group",
    sns: React.createElement(_components.ul, null, React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://mobile.twitter.com/ayamehonda_zeal",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Twitter"))),
    debut: "初撮り人妻ドキュメント 早川友里子",
    debutFanza: "yes",
    debutFanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dh_086jrzd00603%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    debutSokumil: "yes",
    debutSokumilUrl: "https://www.sokmil.com/av/_item/item226863.htm?ref=search"
  }), "\n", React.createElement(HeadlineH4, {
    h4: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup）"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/4.jpg",
    alt: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup）"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup）",
    actress: "朋美",
    release: "2021/05/29",
    time: "120分",
    manufacturer: "乳と母/エマニエル",
    lavel: "乳と母"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "本邦初公開。最高の熟成された国産ビーフはいかがでしょうか？乳、尻、太もも、どこを取っても最高級です。いい喘ぎ声をあげますよ。セックスレスで欲求不満は最高潮です。性欲の溜まったメス牛ほど美味なものはありませんからね。朋美さん、すっごいスケベです。どうぞご覧くださいませ。"
  }), "\n", React.createElement(ReviewText, {
    reviewText: ""
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/4/chch00007jp-2.jpg",
    alt: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/4/chch00007jp-3.jpg",
    alt: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/4/chch00007jp-4.jpg",
    alt: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/4/chch00007jp-5.jpg",
    alt: "ぽっちゃり好きハンターが過去一番エグかった爆乳人妻さん、流出します。朋美（34歳/K-cup"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dchch00007%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(HeadlineH4, {
    h4: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/5.jpg",
    alt: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子",
    actress: "市川京子",
    release: "2021/09/10",
    time: "119",
    manufacturer: "マドンナ",
    lavel: "MONROE",
    manager: "\t小山雅継"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "息子は今年、大学受験。結婚して長い年月を迎えても妻・京子に対して「愛情」も「性欲」もお盛んな夫・健三は、息子が目の前に居ようがお構いなし。食事中に太ももを撫でまわし、キスすら京子に求めた…。良き母として拒む京子だったが、息子が寝た後は健三との激しい交尾にメスの一面も晒していた。そんな京子ともっと変態な行為を楽しみたいと考えた健三は息子が外出する日を見計らい、若い他人棒を自宅に招き入れて…。"
  }), "\n", React.createElement(ReviewText, {
    reviewText: ""
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/5/roe00008jp-1.jpg",
    alt: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/5/roe00008jp-2.jpg",
    alt: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/5/roe00008jp-5.jpg",
    alt: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/5/roe00008jp-7.jpg",
    alt: "「息子が寝た後に私の愛する妻はメスになる…。」 MONROEが描く中年《変態》夫婦ドラマ！！ 市川京子"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Droe00008%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(PerformerInfoTable, {
    name: "市川京子",
    year: "1980",
    month: "11",
    day: "4",
    height: "167",
    bust: "98",
    waist: "60",
    hip: "95",
    birthplace: "和歌山県",
    hobby: "ライブ観戦 ドライブ 海",
    belonging: "ACT",
    sns: React.createElement(_components.ul, null, React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://twitter.com/ichikawa_kyouko",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Twitter")), React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://www.instagram.com/ichikawa_kyouko/",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Instagram"))),
    skill: "",
    debut: "グラマラス×エレガンス 目のやり場に困る Icup人妻―。 市川京子40歳 AV Debut!!",
    debutFanza: "",
    debutFanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Djul00528%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text"
  }), "\n", React.createElement(HeadlineH4, {
    h4: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  }), "\n", React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/6.jpg",
    alt: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  }), "\n", React.createElement(MovieInfoTable, {
    title: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～",
    actress: "丸山ふみな",
    release: "2021/01/17",
    time: "151",
    manufacturer: "乳と母/エマニエル",
    lavel: "乳と母"
  }), "\n", React.createElement(MovieLeadText, {
    readText: "普通のやや古めかしいファミリー向けマンションでした。扉を開け現れたのはセクシーなネグリジェを着た色気抜群の熟女。もうその時点で私のペに夫はビンビン！こんな時代からこそ生まれた奇蹟のソープランド。テクニック抜群、見た目最高、肉感最高、サービスえっぐいです。"
  }), "\n", React.createElement(ReviewText, {
    reviewText: ""
  }), "\n", React.createElement(Goodpoints, {
    goodpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(Badpoints, {
    badpoints: React.createElement(_components.ul, null, React.createElement(_components.li), React.createElement(_components.li), React.createElement(_components.li))
  }), "\n", React.createElement(FiveStarRating), "\n", React.createElement("div", {
    class: "prefaceBox text-center"
  }, React.createElement("div", {
    class: "prefaceBox-title"
  }, "作品ギャラリー"), React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/6/chch00003jp-1.jpg",
    alt: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/6/chch00003jp-2.jpg",
    alt: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/6/chch00003jp-3.jpg",
    alt: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  })), React.createElement(Carousel.Item, null, React.createElement(_components.img, {
    src: "../../images/post/7-voluptuous-mature/6/chch00003jp-5.jpg",
    alt: "カリスマ爆乳人妻ソープ嬢、コロナ禍につき自宅でソープランド開業！～豊満ボディを駆使した超絶テクニックと家庭的サービスにより話題沸騰の店舗を密着ドキュメント～"
  }))), React.createElement("small", null, "左右の矢印のボタンをクリックすると画像がスライドします")), "\n", React.createElement(AspCartBtnBox, {
    fanza: "yes",
    duga: "yes",
    sokumil: "yes",
    sokumilUrl: "https://www.sokmil.com/av/_item/item370956.htm?ref=search",
    fanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dchch00003%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    dugaPath: "ppv/emmanuelle-1761/"
  }), "\n", React.createElement(PerformerInfoTable, {
    name: "丸山ふみな",
    alias: "都丸ふみ奈 田辺八絵",
    year: "1985",
    month: "9",
    day: "18",
    height: "155",
    bust: "110",
    waist: "75",
    hip: "92",
    blood: "",
    birthplace: "",
    hobby: "カメラ　競馬",
    belonging: "リズムプロモーション",
    sns: React.createElement(_components.ul, null, React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://peing.net/ja/q/91c817ce-265c-4094-aae3-8a9ba91a852a",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "質問箱")), React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://twitter.com/maruyama_fumina",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "Twitter")), React.createElement(_components.li, null, React.createElement(_components.a, {
      href: "https://www.amazon.co.jp/hz/wishlist/ls/2KRSTOTZ0F5D6?ref_=wl_share",
      rel: "noreferrer noopener nofollow",
      target: "_blank"
    }, "amazon欲しい物リスト")), React.createElement(_components.li, null, "LINE:@905enilq")),
    skill: "料理",
    debut: "飛びきりの笑顔と敏感過ぎる乳首 大巨乳の四十路妻がAVデビュー！ 丸山ふみな",
    debutFanza: "",
    debutFanzaUrl: "https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3Dmkd00222%2F&af_id=daisukipress-003&ch=toolbar&ch_id=text",
    debutDuga: "",
    debutDugaPath: "ppv/ruby3-0628/",
    debutSokumil: "",
    debutSokumilUrl: "https://www.sokmil.com/av/_item/item373963.htm?ref=search"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
